import Logo from "../assets/images/logoBig.svg"
import { Flex, Image, Text, Spinner } from "@chakra-ui/react";
import { AuthConnect } from "../util/request";
import Header from "../components/header";
import { useHistory, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import Toast from "../components/notify";
import { PieChart } from "react-minimal-pie-chart";
import ring from "../assets/images/Ring.svg"


export default function Metrics({ userData }) {
    const [data, setData] = useState()
    const [loading, setLoading] = useState(true)
    const history = useHistory()
    const zType = sessionStorage.getItem("ZinelyType")

    async function loadData() {
        setLoading(true)
        const res = await AuthConnect("get", "metric/"+(zType === "tax" ? "tax-admin" : "provider")+"/dashboard")
        setData(res?.data)
        setLoading(false)
    }

    useEffect(() => {
        loadData()
    }, [])

    const mArr = zType === "tax" ? 
    [{name: "Total Transactions", sub: parseFloat(data?.transactionCount)?.toLocaleString()},{name: "Volume Processed", sub: "₦"+parseFloat(data?.volumeProcessed)?.toLocaleString()},{name: "Total Remitted", sub: "₦"+parseFloat(data?.taxPaid)?.toLocaleString()},{name: "Transaction Fee", sub: "₦"+parseFloat(data?.fees)?.toLocaleString()},]
    :
    [{name: "Total Transactions", sub: parseFloat(data?.transactionCount)?.toLocaleString()},{name: "Total Processed", sub: "₦"+parseFloat(data?.volumeProcessed)?.toLocaleString()},{name: "Total Fees", sub: "₦"+parseFloat(data?.fees)?.toLocaleString()},{name: "Total Tax Due", sub: "₦"+parseFloat(data?.taxDue)?.toLocaleString()},]


    const tArr = [{name: "Sends", val: data?.transactionBreakdown?.send}, {name: "Sell", val: data?.transactionBreakdown?.sell}, {name: "Buy", val: data?.transactionBreakdown?.buy}, {name: "Bank Transfers", val: data?.transactionBreakdown?.transfer || data?.transactionBreakdown?.bank_transfer}, {name: "Card Payment", val: data?.transactionBreakdown?.payment || data?.transactionBreakdown?.card_payment}]

    const vArr = zType === "tax" ? 
    [{name: "VAT Charged", val: "₦"+parseFloat(data?.taxDue)?.toLocaleString()}, {name: "VAT Remitted", val: "₦"+parseFloat(data?.taxPaid)?.toLocaleString()}, {name: "VAT Oustanding", val: "₦"+parseFloat(data?.taxOutstanding)?.toLocaleString()}]
    :
    [{name: "VAT Due", val: "₦"+parseFloat(data?.taxDue)?.toLocaleString()}, {name: "VAT Paid", val: "₦"+parseFloat(data?.taxPaid)?.toLocaleString()}, {name: "VAT Oustanding", val: "₦"+parseFloat(data?.taxOutstanding)?.toLocaleString()}]

    const defaultLabelStyle = {
        fontSize: '5px',
        fontFamily: 'inter',
      };

    return (
        <Flex w="100%" direction="column" pt="6">
            
            <Flex w="100%" direction="column">
                <Header name={"Welcome "+userData?.firstName} sub="Here’s an overview of your activities" type="Report" />

                <Flex mt="8" w="100%" direction="column">
                    {
                        loading ?
                        <Flex w="100%" justify="center" py="100px" direction="column" align="center">
                            <Spinner color="#7D00FF" emptyColor="lightgrey" w="50px" h="50px" />
                            <Text mt="4" fontSize="12px" color="#475467" textAlign="center">Fetching your dashboard details</Text>
                        </Flex>
                        :
                        <Flex w="100%" direction="column">
                            <Flex w="100%" justify="space-between" flexWrap="wrap">
                                {
                                    mArr?.map((item, index) => (
                                            <Flex key={index} w="24%" border="1px solid #E4E7EC" padding="16px" borderRadius="12px" bg="#fff" direction="column">
                                                <Text color="#475367" fontSize="14px">{item?.name}</Text>
                                                <Text mt="2" fontSize="18px" fontWeight="600" color="#344054">{item.sub}</Text>
                                            </Flex>
                                    ))
                                }
                            </Flex>

                            <Flex mt="6" w="100%" bg="#fff" borderRadius="12px" border='1px solid #E4E7EC'>
                                <Flex direction="column" w="30%">
                                    <Flex color="#101928" fontWeight="600" fontSize="18px" borderBottom="1px solid #E4E7EC" padding="24px">Transaction  Trend</Flex>
                                    <Flex direction="column" padding="0px 24px" borderRight="1px solid #E4E7EC" h="100%">
                                        {
                                            tArr?.map((item, index) => (
                                                <Flex w="100%" justify="space-between" padding="24px 16px" borderBottom="1px solid #F7F9FC" key={index} display={item.val === undefined ? "none" : "flex"}>
                                                    <Flex align="center">
                                                        <Text mr='3' w="8px" h="8px" bg="#7F56D9" borderRadius="100%"></Text>
                                                        <Text color="#667185" textTransform="capitalize" fontSize="14px">{item?.name}</Text>
                                                    </Flex>
                                                    <Text fontSize="14px" fontWeight="500" color="#101928">{parseFloat(item?.val)?.toLocaleString()}</Text>
                                                </Flex>
                                            ))
                                        }
                                    </Flex>
                                </Flex>



                                <Flex direction="column" flex="1">
                                    <Flex color="#101928" fontWeight="600" fontSize="18px" borderBottom="1px solid #E4E7EC" padding="24px 40px">Value Added Tax</Flex>
                                    <Flex padding="24px 40px" direction={zType === "tax" ? "column" : "row"}>
                                        <Flex justify="space-between" w={zType === "tax" ? "100%" : "35%"} direction={zType === "tax" ? "row" : "column"} bg="#F6F7F9" padding="16px 20px" pb="0px" borderRadius="8px">
                                            {
                                                vArr.map((item, index) => (
                                                    <Flex key={index} w={zType === "tax" ? "auto" : "100%"} direction="column">
                                                        <Text color="#475367" fontSize="14px">{item?.name}</Text>
                                                        <Text wordBreak="break-all" color="#101928" fontWeight="600" fontSize="18px" mb="30px">{item?.val}</Text>
                                                    </Flex>
                                                ))
                                            }
                                        </Flex>
                                        {
                                        zType === "tax" ? 
                                        <Flex flex="1" mt="8" direction="column">
                                            <Flex color="#101928" fontWeight="600" fontSize="18px">No. of Providers</Flex>

                                            <Flex mt="6">
                                                <Flex backgroundImage={ring} backgroundRepeat="no-repeat" backgroundPosition="center center" backgroundSize="100%" w="158px" h="158px" direction="column" align="center" justify="center">
                                                    <Text fontSize='14px' color="#667085" fontWeight="500">Providers</Text>
                                                    <Text fontWeight="600" color="#101828" fontSize='28px'>{data?.providerCount}</Text>
                                                </Flex>
                                            </Flex>
                                        </Flex>
                                        :
                                        <Flex flex="1" ml="8" align="center">
                                            <Flex flex="1">
                                                <PieChart data={[
                                                    { title: 'VAT Due', value: data?.taxDue, color: '#B692F6', },
                                                    { title: 'VAT Paid', value: data?.taxPaid, color: '#F4EBFF' },
                                                    { title: 'VAT Outstanding', value: data?.taxOutstanding, color: '#53389E' },
                                                ]} 
                                                label={({ dataEntry }) => parseFloat(dataEntry.percentage)?.toFixed()+"%"}
                                                labelStyle={{
                                                    ...defaultLabelStyle,
                                                }}
                                                />
                                            </Flex>
                                            <Flex ml="5" direction="column">
                                                <Flex align="center">
                                                    <Text mr='3' w="8px" h="8px" bg="#B692F6" borderRadius="100%"></Text>
                                                    <Text color="#667085" textTransform="capitalize" fontSize="14px">VAT Due</Text>
                                                </Flex>

                                                <Flex align="center" my="4">
                                                    <Text mr='3' w="8px" h="8px" bg="#F4EBFF" borderRadius="100%"></Text>
                                                    <Text color="#667085" textTransform="capitalize" fontSize="14px">VAT Paid</Text>
                                                </Flex>

                                                <Flex align="center">
                                                    <Text mr='3' w="8px" h="8px" bg="#53389E" borderRadius="100%"></Text>
                                                    <Text color="#667085" textTransform="capitalize" fontSize="14px">VAT Oustanding</Text>
                                                </Flex>
                                            </Flex>
                                        </Flex>
                                        }
                                    </Flex>
                                </Flex>
                            </Flex>
                        </Flex>
                    }
                </Flex>
            </Flex>
        </Flex>
    )
}