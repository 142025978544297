import { Flex, Image, Text } from "@chakra-ui/react";
import {
	Switch,
	Route,
    Redirect,
} from "react-router-dom";
import { requireAuth } from "../util/request";
import { useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import ScrollToTop from "../components/sc";
import Modal from "../components/modals";
import Metrics from "./metrics";
import MenuBar from "../components/menuBar";
import NavBar from "../components/navBar";
import Reports from "./reports";
import VerifyTransaction from "./verify";
import Settings from "./settings";
import Payments from "./payment";
import Transactions from "./transactions";
import MenuBar2 from "../components/menuBar2";
import Providers from "./providers";

function Dashboard({ userData }) {
    const [modal, setModal] = useState({
        isOpen: false,
        id: "",
        data: "",
        func: "",
        refresh: "",
        reload: "",
    })

    const zType = sessionStorage.getItem("ZinelyType")


    return (
        <ScrollToTop>
        <Flex w="100%" align="flex-start">

            <Flex position="sticky" zIndex="11" top="0" bg={zType === "tax" ? "#FFFFFF" : "#170624" }padding="24px 8px" w="272px" h="100vh"  overflowY="scroll" className="customScroll">
                {zType === "tax" ? <MenuBar2 userData={userData} setModal={setModal} /> : <MenuBar userData={userData} setModal={setModal} />}
            </Flex>

            <Flex flex="1" direction="column" minH="100vh" pb="80px" bg="#F9FAFB">
                <NavBar setModal={setModal} />
                {
                    modal?.isOpen && 
                    <Modal modal={modal} setModal={setModal} />
                }
                <Flex w="100%" direction="column" px="36px">
                    <Switch>
                        <Route
                            path={"/"+(zType === "tax" ? "tax-admin" : "provider")+"/dashboard"}
                            render={() => (
                                <Metrics modal={modal} setModal={setModal} userData={userData}  />
                            )}
                        />
                        <Route
                            path={"/"+(zType === "tax" ? "tax-admin" : "provider")+"/reports/:slug"}
                            render={() => (
                                <Reports isSlug={true} modal={modal} setModal={setModal} />
                            )}
                        />
                        <Route
                            path={"/"+(zType === "tax" ? "tax-admin" : "provider")+"/reports"}
                            render={() => (
                                <Reports modal={modal} setModal={setModal} />
                            )}
                        />
                        <Route
                            path="/provider/settings"
                            render={() => (
                                <Settings userData={userData} modal={modal} setModal={setModal} />
                            )}
                        />
                        <Route
                            path="/tax-admin/providers"
                            render={() => (
                                <Providers userData={userData} modal={modal} setModal={setModal} />
                            )}
                        />
                        <Route
                            path={"/"+(zType === "tax" ? "tax-admin" : "provider")+"/payments"}
                            render={() => (
                                <Payments modal={modal} setModal={setModal} />
                            )}
                        />
                        <Route
                            path={"/"+(zType === "tax" ? "tax-admin" : "provider")+"/transactions"}
                            render={() => (
                                <Transactions modal={modal} setModal={setModal} />
                            )}
                        />
                        <Route
                            path={"/"+(zType === "tax" ? "tax-admin" : "provider")+"/payment/verify/:slug"}
                            render={() => (
                                <VerifyTransaction modal={modal} setModal={setModal} />
                            )}
                        />
                        <Redirect strict from={zType === "tax" ? "/tax-admin" : "/provider"} to={zType === "tax" ? "/tax-admin/dashboard" : "/provider/dashboard"} />
                    </Switch>
                </Flex>
            </Flex>
        </Flex>
        </ScrollToTop>
    )
}

export default requireAuth(Dashboard)