import Logo from "../assets/images/logoBig.svg"
import { Flex, Image, Text, Spinner } from "@chakra-ui/react";
import Status from "./status";

export default function Cards({ bg, title, status, arr }) {
    return (
        <Flex bgColor="#fff" backgroundImage={bg} backgroundSize="90%" backgroundRepeat="no-repeat" backgroundPosition="center center" w="100%" direction="column" border="1px solid #EDEDF2" borderRadius="10px">
            <Flex w="100%" borderBottom="1px solid #E4E7EC" padding="24px 16px" justify="space-between">
                <Text fontSize="16px" fontWeight="600" color="#101928">{title}</Text>
                {
                    status &&
                    <Status status={status} />
                }
            </Flex>
            {
                arr.map((item, index) => (
                    <Flex w="100%" justify="space-between" key={index} padding="24px 16px" borderBottom="1px solid #F7F9FC" display={(((index + 1) === arr?.length) && status === "paid") ? "none" : "flex"}>
                        <Text color="#667185" fontSize="14px">{item?.title}</Text>
                        <Text fontSize="14px" fontWeight="500" color="#101928">{item?.sub}</Text>
                    </Flex>
                ))
            }
        </Flex>
    )
}