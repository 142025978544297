import Logo from "../assets/images/logoBig.svg"
import { Flex, Image, Text, Spinner } from "@chakra-ui/react";
import { useHistory, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { AuthConnect } from "../util/request";
import Header from "../components/header";
import Table from "../components/table";
import TabsMenu from "../components/tabs";
import Back from "../components/back";
import flogo from "../assets/images/flogo.svg"
import { binLogo, errorIcon, checkIcon, pendingIcon, providers_arr } from "../components/svgs";
import Cards from "../components/cards";
import Toast from "../components/notify";
import Status from "../components/status";
import naira from "../assets/images/naira.png"


export default function Providers({ isSlug, setModal }) {
    const { slug } = useParams()
    const [screen, setScreen] = useState(0)
    const history = useHistory()
    const [tab, setTab] = useState(0)
    const [data, setData] = useState()
    const [data2, setData2] = useState()
    const [data3, setData3] = useState()
    const [data4, setData4] = useState()
    const [filter, setFilter] = useState("Filter")
    const [sort, setSort] = useState("Sort")
    const [search, setSearch] = useState("Search")
    const [loading, setLoading] = useState(false)
    const [loading2, setLoading2] = useState(false)
    const [loading3, setLoading3] = useState(false)
    const [loading4, setLoading4] = useState(false)
    const [loading5, setLoading5] = useState(false)
    const [loading6, setLoading6] = useState(false)
    const [loading7, setLoading7] = useState(false)
    const [loading8, setLoading8] = useState(false)
    const [loading9, setLoading9] = useState(false)
    const [loading10, setLoading10] = useState(false)
    const [loading11, setLoading11] = useState(false)
    const [loading12, setLoading12] = useState(false)
    const [loadingx, setLoadingx] = useState(true)
    const [loadinga, setLoadinga] = useState(true)
    const [currentData, setCurrentData] = useState("")
    const [newData, setNewData] = useState("")
    const [lastScroll, setLastScroll] = useState()
    const [metric, setMetric] = useState("")


    async function loadData(direction, id) {
        direction === "prev" ? setLoading3(true) : direction === "next" ? setLoading2(true) : setLoading(true)
        const res = await AuthConnect("get", direction === "prev" ? "report/tax-adm?previous="+id : direction === "next" ? "provider/admin?next="+id : "provider/admin")
        setData(res)
        console.log(res)
        direction === "prev" ? setLoading3(false) : direction === "next" ? setLoading2(false) : setLoading(false)
    }

    async function loadData2(direction, id) {
        direction === "prev" ? setLoading6(true) : direction === "next" ? setLoading5(true) : setLoading4(true)
        const res = await AuthConnect("get", direction === "prev" ? "provider/admin?status=active&previous="+id : direction === "next" ? "provider/admin?status=active&next="+id : "provider/admin?status=active")
        setData2(res)
        direction === "prev" ? setLoading6(false) : direction === "next" ? setLoading5(false) : setLoading4(false)
    }

    async function loadData3(direction, id) {
        direction === "prev" ? setLoading9(true) : direction === "next" ? setLoading8(true) : setLoading7(true)
        const res = await AuthConnect("get", direction === "prev" ? "provider/admin?status=pending&previous="+id : direction === "next" ? "provider/admin?status=pending&next="+id : "provider/admin?status=pending")
        setData3(res)
        direction === "prev" ? setLoading9(false) : direction === "next" ? setLoading8(false) : setLoading7(false)
    }

    async function loadData4(direction, id) {
        direction === "prev" ? setLoading12(true) : direction === "next" ? setLoading11(true) : setLoading10(true)
        const res = await AuthConnect("get", direction === "prev" ? "provider/admin?status=failed&previous="+id : direction === "next" ? "provider/admin?status=failed&next="+id : "provider/admin?status=failed")
        setData4(res)
        direction === "prev" ? setLoading12(false) : direction === "next" ? setLoading11(false) : setLoading10(false)
    }

    async function loadMetrics() {
        setLoadinga(true)
        const res = await AuthConnect("get", "metric/tax-admin/provider-summary")
        setMetric(res?.data)
        setLoadinga(false)
    }

    useEffect(() => {
        loadMetrics()
        loadData()
        loadData2()
        loadData3()
        loadData4()
    }, [])

    const tabs = ["All Provider", "Active", "Pending", "Failed"]
    const tableHead = ["Date Registered", "Providers", "Transactions", "VAT Due", "Amount Remitted",  "Last Sync", "Status"]

    async function updateScreen(item) {
        setLastScroll(window.scrollY)
        setScreen(1)
        setCurrentData(item)
    }

    async function getData() {
        setLoadingx(false)
        setNewData(currentData)
    }
    useEffect(() => {
        if(screen === 1) {
            window.scrollTo(0,0)
            getData()
        }
        else {
            setLoadingx(true)
        }
    }, [screen])


    const mArr = [{name: "All Providers", sub: parseFloat(metric?.providerCount)?.toLocaleString()},{name: "Compliant Providers", sub: parseFloat(metric?.compliant)?.toLocaleString()},{name: "Non-Compliant Providers", sub: parseFloat(metric?.nonCompliant)?.toLocaleString()},{name: "Active", sub: parseFloat(metric?.active)?.toLocaleString()},]

    
    const cArr = [{title: "Company Name:", sub: newData?.profile?.businessName},{title: "UID:", sub: newData?.uid},{title: "Tax Paid:", sub: "₦"+parseFloat(newData?.profile?.taxPaid)?.toLocaleString()},{title: "Tax Due:", sub: "₦"+parseFloat(newData?.profile?.taxDue)?.toLocaleString()},{title: "Tax Oustanding:", sub: "₦"+parseFloat(newData?.profile?.taxOutstanding)?.toLocaleString()},{title: "Type:", sub: newData?.type},{title: "Volume Processed:", sub: "₦"+parseFloat(newData?.profile?.volumeProcessed)?.toLocaleString()},{title: "Tranasaction Count:", sub: parseFloat(newData?.profile?.transactionCount)?.toLocaleString()},{title: "No. of Filings:", sub: parseFloat(newData?.profile?.filingsCount)?.toLocaleString()},{title: "Last Sync Date:", sub: newData?.profile?.lastSync ? newData?.profile?.lastSync?.substr(0,10) : "---"}]

    const iArr = [{title: "Amount Remitted:", sub: "₦"+parseFloat(newData?.profile?.taxDue)?.toLocaleString()},{title: "Status:", sub: <Status status={newData?.status}/>},{title: "Account Type:", sub: newData?.profile?.accountType},{title: "Contact Email:", sub: newData?.contactEmail},{title: "Contact:", sub: newData?.contactPhoneNumber},{title: "Contact Address:", sub: newData?.contactAddress ?? "---"},{title: "Connection Status:", sub: <Status status={newData?.connectionStatus}/>}, {title: "Processing Fees:", sub: parseFloat(newData?.profile?.processingFee)?.toLocaleString()},]



    function getProviderImage(key) {
        return providers_arr[key] || naira;
    }




    return (
        <Flex w="100%" direction="column" pt="6">
            
            <Flex w="100%" direction="column" display={screen === 0 ? "flex" : "none"}>
                <Header name="Providers" sub="Manage your providers here" type="Provider" />

                {!loadinga && metric !== "" && <Flex w="100%" justify="space-between" flexWrap="wrap" mt='10'>
                    {
                        mArr?.map((item, index) => (
                                <Flex key={index} w="24%" border="1px solid #E4E7EC" padding="16px" borderRadius="12px" bg="#fff" direction="column">
                                    <Text color="#475367" fontSize="14px">{item?.name}</Text>
                                    <Text mt="2" fontSize="18px" fontWeight="600" color="#344054">{item.sub}</Text>
                                </Flex>
                        ))
                    }
                </Flex>}

                <Flex w="100%" direction="column" mt="8">
                    <TabsMenu tabs={tabs} tab={tab} setTab={setTab} setModal={setModal} filter={filter} setFilter={setFilter} sort={sort} setSort={setSort} search={search} setSearch={setSearch} />
                </Flex>

                <Flex direction="column" mt="5" w="100%">
                    {
                        tab === 0 ?
                        <Table type="provider" data={data} loading={loading} loading2={loading2} loading3={loading3} tableHead={tableHead} loadData={loadData} updateScreen={updateScreen} />
                        :
                        tab === 1 ?
                        <Table type="provider" data={data2} loading={loading4} loading2={loading5} loading3={loading6} tableHead={tableHead} loadData={loadData2} updateScreen={updateScreen} />
                        :
                        tab === 2 ?
                        <Table type="provider" data={data3} loading={loading7} loading2={loading8} loading3={loading9} tableHead={tableHead} loadData={loadData3} updateScreen={updateScreen} />
                        :
                        tab === 3 ?
                        <Table type="provider" data={data4} loading={loading10} loading2={loading11} loading3={loading12} tableHead={tableHead} loadData={loadData4} updateScreen={updateScreen} />
                        :
                        <></>
                    }
                </Flex>
            </Flex>















            <Flex w="100%" direction="column" display={screen === 1 ? "flex" : "none"}>
                <Back type="provider" lastScroll={lastScroll} setAction={setScreen} />
                <Flex w="100%" direction="column" mt="8">
                    {
                        loadingx ?
                        <Flex w="100%" justify="center" py="100px" direction="column" align="center">
                            <Spinner color="#7D00FF" emptyColor="lightgrey" w="50px" h="50px" />
                            <Text mt="4" fontSize="12px" color="#475467" textAlign="center">Fetching this provider details</Text>
                        </Flex>
                        :
                        <Flex w="100%" direction="column">
                            <Flex w="100%" border="1px solid #E4E7EC" bg="#fff" padding="32px" borderRadius="10px" justify="space-between" align="flex-start">
                                <Flex align="center">
                                    <Image src={getProviderImage(newData?.profile?.businessName)} w="58px" h="58px" borderRadius="3.2px" />
                                    <Text ml="5">
                                        <Text fontSize="28px" color="#101928" fontWeight="600" textTransform="capitalize">{newData?.profile?.businessName}</Text>
                                        <Text color="#475467" fontSize="14px">{newData?.profile?.providerId}</Text>
                                    </Text>
                                </Flex>
                            </Flex>
                            
                            <Flex w="100%" mt="4" justify="space-between" align="flex-start">
                                <Flex w="48%">
                                    <Cards title="Platform Details" arr={cArr} />
                                </Flex>
                                <Flex w="48%">
                                    <Cards title="Other Details" arr={iArr} />
                                </Flex>
                            </Flex>
                        </Flex>
                    }
                </Flex>
            </Flex>




        </Flex>
    )
}