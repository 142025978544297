import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Index from "./index";
import Dashboard from "./dashboard";
import Auth from "./auth";
import "../styles/global.css";
import { ChakraProvider, Flex, Image, Text } from "@chakra-ui/react";
import SignUp from "./signup";


function App() {
  const iniUserData = JSON.parse(sessionStorage.getItem("userData"))
  const [userData, setUserData] = useState(sessionStorage.getItem("userData") !== null ? iniUserData : "")
  return (
    <ChakraProvider>
      <Flex
        direction="column"
        display={["none", "flex"]}
        fontSize="14px"
        color="#101928"
      >
        <Router>
          <Switch>
            <Route path="/" component={Index} exact />
            <Route
              path="/tax-admin"
              render={() => (
                <Dashboard userData={userData} setUserData={setUserData} />
              )}
            />
            <Route
              path="/provider"
              render={() => (
                <Dashboard userData={userData} setUserData={setUserData} />
              )}
            />
            <Route path="/auth/signin" 
              render={() => (
                <Auth setUserData={setUserData} />
              )} />
            <Route path="/auth/signup" 
              render={() => (
                <SignUp setUserData={setUserData} />
              )} />
          </Switch>
        </Router>
      </Flex>

      <Flex display={["flex", "none"]} w="100%" h="100vh" justify="center" align="center" fontSize="15px" direction="column">
        <Text mt="6" fontWeight="600" px="15%" textAlign="center">This app is optimized for desktop view only</Text>
        <Text mt="5" textAlign="center">Kindly use a PC to access this app</Text>
      </Flex>
    </ChakraProvider>
  );
}

export default App;
