import Logo from "../assets/images/logoBig.svg"
import { Flex, Image, Text, Spinner } from "@chakra-ui/react";

export default function Header({ name, sub, type }) {
    const isExport = ["Report", "Transactions"].includes(type)
    
    return (
        <Flex w="100%" justify="space-between" align="flex-start">
            <Flex direction="column">
                <Text color="#000" fontSize="24px" fontWeight="600">{name}</Text>
                <Text color="#475367" fontSize="16px" mt="1">{sub}</Text>
            </Flex>
            {/*
                isExport &&
                <Flex align='center' justify="center" bg="#7D00FF" color="#fff" padding="8px 16px" fontSize="14px" borderRadius="8px" cursor="pointer" fontWeight="600">Export {type}</Flex>
            */}
        </Flex>
    )
}