import Logo from "../assets/images/logoBig.svg"
import { Flex, Image, Text, Spinner } from "@chakra-ui/react";
import { bkIcon, pIcon } from "./svgs";
import { useHistory } from "react-router-dom";

export default function Back({ type, lastScroll, setAction, isSlug }) {
    const history = useHistory()
    const zType = sessionStorage.getItem("ZinelyType")
    return (
        <Flex w="100%" direction="column">
            <Flex cursor="pointer" align='center' color="#7D00FF" onClick={() => {
                if(isSlug) {
                    setAction(0)
                    history.push("/"+(zType === "tax" ? "tax-admin" : "provider")+"/reports")
                }
                else {
                    setAction(0)
                    if(lastScroll) {
                        window.scrollTo(0, lastScroll)
                    }
                }
            }} _hover={{ textDecoration: "underline" }}>
                {bkIcon}
                <Text fontWeight='500' color="#7D00FF" ml="3" fontSize="14px">{isSlug ? "Go back" : <>Go back to <Text textTransform="capitalize" as="span">{type}s</Text></>}</Text>
            </Flex>
            <Flex w='100%' justify="space-between" mt="8" align="center">
                <Text color="#101928" fontSize="24px" fontWeight="600" textTransform="capitalize">{type} Summary</Text>
                <Flex cursor="pointer">{pIcon}</Flex>
            </Flex>
        </Flex>
    )
}