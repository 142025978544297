import Logo from "../assets/images/logoBig.svg"
import { Flex, Image, Text, Spinner } from "@chakra-ui/react";
import { AuthConnect } from "../util/request";
import Header from "../components/header";
import { useHistory, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import Toast from "../components/notify";


export default function VerifyTransaction() {
    const [loading, setLoading] = useState(true)
    const zType = sessionStorage.getItem("ZinelyType")
    const { slug } = useParams()
    const history = useHistory()
    const urlParams = new URLSearchParams(window.location.search);
    const ref = urlParams.get('tx_ref');

    useEffect(async () => {
        const res = await AuthConnect("post", "payment/process", {
            reference: ref,
        })
        if(res?.success) {
            Toast(res?.message, "success")
            setTimeout(function() {
                history.push("/"+(zType === "tax" ? "tax-admin" : "provider")+"/reports/"+slug)
            }, 1500)
        }
    }, [])

    return (
        <Flex w="100%" direction="column" mt="6">
            <Header name="Payment" sub="Process payment made" type="Verify" />
            <Flex w="100%" justify="center" mt="8">
                <Flex w="400px" py="100px" direction="column" borderRadius="10px" bg="#fff" px="24px" align="center" justify='center' border="1px solid #E4E7EC">
                    <Spinner color="#7D00FF" emptyColor="lightgrey" w="80px" h="80px" />
                    <Text mt="4" fontSize="14px" color="#475467" textAlign="center">Processing your payment</Text>
                </Flex>
            </Flex>
        </Flex>
    )
}