import Logo from "../assets/images/logoBig.svg"
import { Flex, Image, Text, Spinner } from "@chakra-ui/react";
import { useHistory, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { AuthConnect } from "../util/request";
import Header from "../components/header";
import Table from "../components/table";
import TabsMenu from "../components/tabs";
import Back from "../components/back";
import flogo from "../assets/images/flogo.svg"
import { binLogo, errorIcon, checkIcon, pendingIcon, providers_arr } from "../components/svgs";
import Cards from "../components/cards";
import Toast from "../components/notify";
import naira from "../assets/images/naira.png"


export default function Reports({ isSlug, setModal }) {
    const { slug } = useParams()
    const [screen, setScreen] = useState(isSlug ? 1 : 0)
    const zType = sessionStorage.getItem("ZinelyType")
    const history = useHistory()
    const [tab, setTab] = useState(0)
    const [data, setData] = useState()
    const [data2, setData2] = useState()
    const [data3, setData3] = useState()
    const [data4, setData4] = useState()
    const [filter, setFilter] = useState("Filter")
    const [sort, setSort] = useState("Sort")
    const [search, setSearch] = useState("Search")
    const [loading, setLoading] = useState(false)
    const [loading2, setLoading2] = useState(false)
    const [loading3, setLoading3] = useState(false)
    const [loading4, setLoading4] = useState(false)
    const [loading5, setLoading5] = useState(false)
    const [loading6, setLoading6] = useState(false)
    const [loading7, setLoading7] = useState(false)
    const [loading8, setLoading8] = useState(false)
    const [loading9, setLoading9] = useState(false)
    const [loading10, setLoading10] = useState(false)
    const [loading11, setLoading11] = useState(false)
    const [loading12, setLoading12] = useState(false)
    const [loadingx, setLoadingx] = useState(true)
    const [loadinga, setLoadinga] = useState(false)
    const [currentData, setCurrentData] = useState("")
    const [newData, setNewData] = useState("")
    const [lastScroll, setLastScroll] = useState()


    async function loadData(direction, id) {
        direction === "prev" ? setLoading3(true) : direction === "next" ? setLoading2(true) : setLoading(true)
        const res = await AuthConnect("get", direction === "prev" ? "report/"+(zType === "tax" ? "admin" : "provider")+"?previous="+id : direction === "next" ? "report/"+(zType === "tax" ? "admin" : "provider")+"?next="+id : "report/"+(zType === "tax" ? "admin" : "provider"))
        setData(res)
        direction === "prev" ? setLoading3(false) : direction === "next" ? setLoading2(false) : setLoading(false)
    }

    async function loadData2(direction, id) {
        direction === "prev" ? setLoading6(true) : direction === "next" ? setLoading5(true) : setLoading4(true)
        const res = await AuthConnect("get", direction === "prev" ? "report/"+(zType === "tax" ? "admin" : "provider")+"?status=paid&previous="+id : direction === "next" ? "report/"+(zType === "tax" ? "admin" : "provider")+"?status=paid&next="+id : "report/"+(zType === "tax" ? "admin" : "provider")+"?status=paid")
        setData2(res)
        direction === "prev" ? setLoading6(false) : direction === "next" ? setLoading5(false) : setLoading4(false)
    }

    async function loadData3(direction, id) {
        direction === "prev" ? setLoading9(true) : direction === "next" ? setLoading8(true) : setLoading7(true)
        const res = await AuthConnect("get", direction === "prev" ? "report/"+(zType === "tax" ? "admin" : "provider")+"?status=pending&previous="+id : direction === "next" ? "report/"+(zType === "tax" ? "admin" : "provider")+"?status=pending&next="+id : "report/"+(zType === "tax" ? "admin" : "provider")+"?status=pending")
        setData3(res)
        direction === "prev" ? setLoading9(false) : direction === "next" ? setLoading8(false) : setLoading7(false)
    }

    async function loadData4(direction, id) {
        direction === "prev" ? setLoading12(true) : direction === "next" ? setLoading11(true) : setLoading10(true)
        const res = await AuthConnect("get", direction === "prev" ? "report/"+(zType === "tax" ? "admin" : "provider")+"?status=overdue&previous="+id : direction === "next" ? "report/"+(zType === "tax" ? "admin" : "provider")+"?status=overdue&next="+id : "report/"+(zType === "tax" ? "admin" : "provider")+"?status=overdue")
        setData4(res)
        direction === "prev" ? setLoading12(false) : direction === "next" ? setLoading11(false) : setLoading10(false)
    }

    useEffect(() => {
        loadData()
        loadData2()
        loadData3()
        loadData4()
    }, [])

    const tabs = ["All Report", "Paid", "Pending", "Overdue"]
    const tableHead = ["Tax Period", "Provider", "Volume Processed", "Processing Fee", "VAT Due", "VAT Paid", "Status"]

    async function updateScreen(item) {
        setLastScroll(window.scrollY)
        setScreen(1)
        setCurrentData(item)
    }

    async function getData() {
        setLoadingx(true)
        var id = isSlug ? slug : currentData?.filingId
        const res = await AuthConnect("get", "report/provider/"+id)
        if(res?.data === null) {
            Toast("No reports record found", "error")
            history.goBack()
            setScreen(0)
        }
        else {
            setNewData(res?.data)
        }
        setLoadingx(false)
    }
    useEffect(() => {
        if(screen === 1) {
            window.scrollTo(0,0)
            getData()
        }
        else {
            setLoadingx(true)
        }
    }, [screen])

    
    const cArr = [{title: "Amount Payable:", sub: "₦"+parseFloat(newData?.taxDue)?.toLocaleString()},{title: "Transaction Fee:", sub: "₦"+parseFloat(newData?.fees)?.toLocaleString()},{title: "Payment Reference:", sub: newData?.id},{title: "Tax Period:", sub: newData?.taxPeriod},{title: "Filing ID:", sub: newData?.filingId},{title: "Transaction Volumes:", sub: parseFloat(newData?.volumeProcessed)?.toLocaleString()},{title: "VAT Due:", sub: "₦"+parseFloat(newData?.taxDue)?.toLocaleString()},{title: "Tax Type:", sub: newData?.taxType},{title: "Due Date:", sub: newData?.dueDate?.substr(0,10)},]


    async function makePayment() {
        setLoadinga(true)
        const res = await AuthConnect("post", "report/pay", {
            filingId: newData?.filingId
        })
        if(res?.success) {
            window.location.href = res?.data?.authorization_url
        }
        setLoadinga(false)
    }

    function getProviderImage(key) {
        return providers_arr[key] || naira;
    }


    const tArr = [{name: "Buy", count: newData?.transactionBreakdown?.buy},{name: "Sell", count: newData?.transactionBreakdown?.sell},{name: "Send", count: newData?.transactionBreakdown?.send}, {name: "Bank Transfers", count: newData?.transactionBreakdown?.transfer || newData?.transactionBreakdown?.bank_transfer}, {name: "Card Payment", count: newData?.transactionBreakdown?.payment || newData?.transactionBreakdown?.card_payment}]




    return (
        <Flex w="100%" direction="column" pt="6">
            
            <Flex w="100%" direction="column" display={screen === 0 ? "flex" : "none"}>
                <Header name="Report" sub="Manage your Value Added Tax Reports here" type="Report" />

                <Flex w="100%" direction="column" mt="8">
                    <TabsMenu tabs={tabs} tab={tab} setTab={setTab} setModal={setModal} filter={filter} setFilter={setFilter} sort={sort} setSort={setSort} search={search} setSearch={setSearch} />
                </Flex>

                <Flex direction="column" mt="5" w="100%">
                    {
                        tab === 0 ?
                        <Table type="report" data={data} loading={loading} loading2={loading2} loading3={loading3} tableHead={tableHead} loadData={loadData} updateScreen={updateScreen} />
                        :
                        tab === 1 ?
                        <Table type="report" data={data2} loading={loading4} loading2={loading5} loading3={loading6} tableHead={tableHead} loadData={loadData2} updateScreen={updateScreen} />
                        :
                        tab === 2 ?
                        <Table type="report" data={data3} loading={loading7} loading2={loading8} loading3={loading9} tableHead={tableHead} loadData={loadData3} updateScreen={updateScreen} />
                        :
                        tab === 3 ?
                        <Table type="report" data={data4} loading={loading10} loading2={loading11} loading3={loading12} tableHead={tableHead} loadData={loadData4} updateScreen={updateScreen} />
                        :
                        <></>
                    }
                </Flex>
            </Flex>















            <Flex w="100%" direction="column" display={screen === 1 ? "flex" : "none"}>
                <Back type="report" lastScroll={lastScroll} setAction={setScreen} isSlug={isSlug} />
                <Flex w="100%" direction="column" mt="8">
                    {
                        loadingx ?
                        <Flex w="100%" justify="center" py="100px" direction="column" align="center">
                            <Spinner color="#7D00FF" emptyColor="lightgrey" w="50px" h="50px" />
                            <Text mt="4" fontSize="12px" color="#475467" textAlign="center">Fetching this report details</Text>
                        </Flex>
                        :
                        <Flex w="100%" direction="column">
                            <Flex w="100%" border="1px solid #E4E7EC" bg="#fff" padding="32px" borderRadius="10px" justify="space-between" align="flex-start">
                                <Flex align="center">
                                    <Image src={getProviderImage(newData?.provider?.businessName)} w="58px" h="58px" borderRadius="3.2px" />
                                    <Text ml="5">
                                        <Text fontSize="28px" color="#101928" fontWeight="600" textTransform="capitalize">{newData?.provider?.businessName}</Text>
                                        <Text color="#475467" fontSize="14px">{newData?.provider?.providerId}</Text>
                                    </Text>
                                </Flex>
                                {zType !== "tax" ? <Flex>
                                    <Flex align='center' justify="center" bg={newData?.status === "paid" ? "#D0D5DD" : "#7D00FF" }color="#fff" padding="8px 16px" fontSize="14px" borderRadius="8px" cursor={newData?.status === "paid" ? "not-allowed" : "pointer"} fontWeight="600" onClick={() => newData?.status !== "paid" && makePayment()}>{loadinga ? <>Processing <Spinner ml="2" color="#fff" emptyColor="lightgrey" /></> : "Make Payment"}</Flex>
                                </Flex>
                                :
                                newData?.status !== "paid" && <Flex cursor="pointer" color="#101928" fontWeight="600" fontSize="14px" justify='center' align="center" padding="6px 16px" borderRadius="8px" border="1px solid #101928">Send Reminder</Flex>}
                            </Flex>
                            
                            <Flex w="100%" mt="4" justify="space-between" align="flex-start">
                                <Flex w="60%">
                                    <Cards bg={flogo} title="Report Details" status={newData?.status} arr={cArr} />
                                </Flex>
                                <Flex w='38%'>
                                    <Flex bg="#fff" w="100%" direction="column" border="1px solid #EDEDF2" borderRadius="10px">
                                        <Flex w="100%" padding="24px 16px" direction="column" align="center">
                                            {
                                                newData?.status === "paid" ? checkIcon :  newData?.status === "pending" ? pendingIcon : errorIcon
                                            }
                                            <Text textAlign="center" mt="3" fontWeight="600" color="#101928" fontSize="36px" textTransform="capitalize">{newData?.status}</Text>
                                            <Text textAlign="center" color={newData?.status === "paid" ? "#219653" : "#04326B"} fontWeight="500" fontSize='16px'>{"₦"+parseFloat(newData?.taxDue)?.toLocaleString()}</Text>
                                        </Flex>
                                        <Flex padding="24px 16px" fontWeight="600" fontSize="16px" color="#101928" borderTop="1px solid #EDEDF2" borderBottom="1px solid #EDEDF2">Transaction Breakdown</Flex>
                                        <Flex w="100%" justify="space-between" padding="24px 16px" borderBottom="1px solid #F7F9FC">
                                            <Text color="#667185" fontSize="14px">Total:</Text>
                                            <Text fontSize="14px" fontWeight="500" color="#101928">{parseFloat(newData?.transactionCount)?.toLocaleString()}</Text>
                                        </Flex>
                                        {
                                            tArr?.map((item, index) => (
                                                <Flex w="100%" justify="space-between" padding="24px 16px" borderBottom="1px solid #F7F9FC" key={index} display={item.count === undefined ? "none" : "flex"}>
                                                    <Text color="#667185" textTransform="capitalize" fontSize="14px">{item?.name}</Text>
                                                    <Text fontSize="14px" fontWeight="500" color="#101928">{parseFloat(item?.count)?.toLocaleString()}</Text>
                                                </Flex>
                                            ))
                                        }
                                    </Flex>
                                </Flex>
                            </Flex>
                        </Flex>
                    }
                </Flex>
            </Flex>




        </Flex>
    )
}