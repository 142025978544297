import { Flex, Image, Text, Box, Spinner } from "@chakra-ui/react";
import InputHolder from "./inputHolder";
import Status from "./status";
import { crpto_arr, providers_arr } from "./svgs";
import naira from "../assets/images/naira.png"


export default function Table({ type, data, loading, loading2, loading3, tableHead, loadData, updateScreen }) {

    function getProviderImage(key) {
        return providers_arr[key] || naira;
    }

    function getCryptoImage(key) {
        return crpto_arr[key] || naira;
    }

    return (
        <>
        <Flex w="100%" direction="column" border="1px solid #E4E7EC" borderRadius="10px">
            <Flex w="100%" bg="#F9FAFB" borderRadius="10px 10px 0px 0px" borderBottom="1px solid #E4E7EC" align="flex-start">
                {
                    tableHead.map((item, index) => (
                        <Flex 
                        w={
                            (item === "Status" || item === "Activated") ? "160px" : type === "transaction2" ? "14.2857143%" : (type === "report" || type === "payment" || type === "provider") ? "16.6666667%" : "20%"
                        }
                        padding="12px 24px" fontWeight="500" fontSize="12px" color="#344054" key={index} wordBreak="break-all">{item}</Flex>
                    ))
                }
            </Flex>
            {
                loading ?
                <Flex w="100%" bg="#fff" justify="center" py="100px">
                    <Spinner color="#7D00FF" emptyColor="lightgrey" w="50px" h="50px" />
                </Flex>
                :
                Array.isArray(data?.data?.docs) && data?.data?.docs?.length > 0 ?
                data?.data?.docs?.map((item, index) => (
                    <Flex w="100%" bg="#fff" borderBottom="1px solid #E4E7EC" key={index} fontSize="12px" fontWeight="500" align="flex-start" cursor="pointer" transition="300ms ease-in-out" _hover={{ bg: "#F9FAFB" }} borderRadius={(index + 1) === data?.data?.docs?.length && "0px 0px 10px 10px"} onClick={() => updateScreen(item)}>
                        {
                            type === "report" ?
                            <>
                                <Flex w="16.6666667%" flexWrap="wrap" wordBreak="break-all" padding="16px 24px" textTransform="capitalize">{item?.taxPeriod}</Flex>
                                <Flex w="16.6666667%" flexWrap="wrap" wordBreak="break-all" padding="16px 24px" align="center" textTransform="capitalize">
                                    <Image w="28px" h="28px" borderRadius="3.2px" src={getProviderImage(item?.provider?.businessName)} mr="3" />
                                    {item?.provider?.businessName}
                                </Flex>
                                <Flex w="16.6666667%" flexWrap="wrap" wordBreak="break-all" padding="16px 24px">{parseFloat(item?.volumeProcessed)?.toLocaleString()}</Flex>
                                <Flex w="16.6666667%" flexWrap="wrap" wordBreak="break-all" padding="16px 24px">{parseFloat(item?.fees)?.toLocaleString()}</Flex>
                                <Flex w="16.6666667%" flexWrap="wrap" wordBreak="break-all" padding="16px 24px">{parseFloat(item?.taxDue)?.toLocaleString()}</Flex>
                                <Flex w="16.6666667%" flexWrap="wrap" wordBreak="break-all" padding="16px 24px">{parseFloat(item?.taxPaid)?.toLocaleString()}</Flex>
                                <Flex padding="16px 24px" w="160px">
                                    <Status status={item?.status} />
                                </Flex>
                            </>
                            :
                            type === "provider" ?
                            <>
                                <Flex w="16.6666667%" flexWrap="wrap" wordBreak="break-all" padding="16px 24px">{item?.createdAt?.substr(0,10)}</Flex>
                                <Flex w="16.6666667%" flexWrap="wrap" wordBreak="break-all" padding="16px 24px" textTransform="capitalize" align="center">
                                    <Image w="28px" h="28px" borderRadius="3.2px" src={getProviderImage(item?.profile?.businessName)} mr="3" />
                                    {item?.profile?.businessName}
                                </Flex>
                                <Flex w="16.6666667%" flexWrap="wrap" wordBreak="break-all" padding="16px 24px">{parseFloat(item?.profile?.transactionCount)?.toLocaleString()}</Flex>
                                <Flex w="16.6666667%" flexWrap="wrap" wordBreak="break-all" padding="16px 24px">{parseFloat(item?.profile?.taxDue)?.toLocaleString()}</Flex>
                                <Flex w="16.6666667%" flexWrap="wrap" wordBreak="break-all" padding="16px 24px">{"₦"+parseFloat(item?.profile?.taxPaid)?.toLocaleString()}</Flex>
                                <Flex w="16.6666667%" flexWrap="wrap" wordBreak="break-all" padding="16px 24px">{item?.profile?.lastSync?.substr(0,10)}</Flex>
                                <Flex padding="16px 24px" w="160px">
                                    <Status status={item?.status} />
                                </Flex>
                            </>
                            :
                            type === "payment" ?
                            <>
                                <Flex w="16.6666667%" flexWrap="wrap" wordBreak="break-all" padding="16px 24px">{item?.createdAt?.substr(0,10)}</Flex>
                                <Flex w="16.6666667%" flexWrap="wrap" wordBreak="break-all" padding="16px 24px" textTransform="capitalize" align='center'>
                                    <Image w="28px" h="28px" borderRadius="3.2px" src={getProviderImage(item?.provider?.businessName)} mr="3" />
                                    {item?.provider?.businessName}
                                </Flex>
                                <Flex w="16.6666667%" flexWrap="wrap" wordBreak="break-all" padding="16px 24px">{"₦"+parseFloat(item?.amount)?.toLocaleString()}</Flex>
                                <Flex w="16.6666667%" flexWrap="wrap" wordBreak="break-all" padding="16px 24px">{item?.reference}</Flex>
                                <Flex w="16.6666667%" flexWrap="wrap" wordBreak="break-all" padding="16px 24px">{item?.paymentProvider}</Flex>
                                <Flex w="16.6666667%" flexWrap="wrap" wordBreak="break-all" padding="16px 24px">{item?.dateVerified ? item?.dateVerified?.substr(0,10) : "---"}</Flex>
                                <Flex padding="16px 24px" w="160px">
                                    <Status status={item?.status} />
                                </Flex>
                            </>
                            :
                            type === "transaction" ?
                            <>
                                <Flex w="20%" flexWrap="wrap" wordBreak="break-all" padding="16px 24px">{item?.createdAt?.substr(0,10)}</Flex>
                                <Flex w="20%" flexWrap="wrap" wordBreak="break-all" padding="16px 24px" textTransform="capitalize" align='center'><Image w="28px" h="28px" borderRadius="3.2px" src={getProviderImage(item?.provider?.businessName)} mr="3" /> {item?.provider?.businessName}</Flex>
                                <Flex w="20%" flexWrap="wrap" wordBreak="break-all" padding="16px 24px">{parseFloat(item?.amount)?.toLocaleString()+" "+item?.currency}</Flex>
                                <Flex w="20%" flexWrap="wrap" wordBreak="break-all" padding="16px 24px">{"₦"+parseFloat(item?.fee)?.toLocaleString()}</Flex>
                                <Flex w="20%" flexWrap="wrap" wordBreak="break-all" padding="16px 24px">{"₦"+parseFloat(item?.VAT)?.toLocaleString()}</Flex>
                            </>
                            :
                            type === "transaction2" ?
                            <>
                                <Flex w="14.2857143%" flexWrap="wrap" wordBreak="break-all" padding="16px 24px" direction="column"><Text>{item?.syncDate?.substr(0,10)}</Text><Text fontSize="10px" color="#667185">{item?.syncDate?.substr(11,5)}</Text></Flex>
                                <Flex w="14.2857143%" flexWrap="wrap" wordBreak="break-all" padding="16px 24px" textTransform="capitalize" align='center'><Image w="28px" h="28px" borderRadius="3.2px" src={getProviderImage(item?.provider?.businessName)} mr="3" /> {item?.provider?.businessName}</Flex>
                                <Flex w="14.2857143%" flexWrap="wrap" wordBreak="break-all" padding="16px 24px">{item?.type}</Flex>
                                <Flex w="14.2857143%" flexWrap="wrap" wordBreak="break-all" padding="16px 24px" align='center'><Image w="28px" h="28px" borderRadius="100%" src={getCryptoImage(item?.currency)} mr="3" /> {item?.currency}</Flex>
                                <Flex w="14.2857143%" flexWrap="wrap" wordBreak="break-all" padding="16px 24px">{"₦"+parseFloat(item?.nairaValue)?.toLocaleString()}</Flex>
                                <Flex w="14.2857143%" flexWrap="wrap" wordBreak="break-all" padding="16px 24px">{parseFloat(item?.fee)?.toLocaleString()}</Flex>
                                <Flex w="14.2857143%" flexWrap="wrap" wordBreak="break-all" padding="16px 24px">{"₦"+parseFloat(item?.VAT)?.toLocaleString()}</Flex>
                            </>
                            :
                            <></>
                        }
                    </Flex>
                ))
                :
                <Flex w="100%" bg="#fff" justify="center" py="100px" color="#475467" fontWeight="500">No Data</Flex>
            }
        </Flex>
        {
            (data?.data?.next || data?.data?.previous) &&
            <Flex w="100%" justify="space-between" padding="24px 0px">
                {
                    data?.data?.previous &&
                    <Flex bg="#fff" border="1px solid #E4E7EC" boxShadow="0px 1px 2px 0px #1018280D" borderRadius="8px" padding="8px 14px" align="center" fontSize="14px" fontWeight="500" color="#344054" onClick={() => loadData("prev", data?.data?.previous)} cursor="pointer">
                    {
                        loading3 ?
                        <Spinner color="#7D00FF" emptyColor="lightgrey" />
                        :
                        <>   
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.8334 10.0001H4.16675M4.16675 10.0001L10.0001 15.8334M4.16675 10.0001L10.0001 4.16675" stroke="#344054" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/></svg>
                            <Text ml="3">Previous</Text>
                        </>
                    }
                    </Flex>
                }

                {
                    data?.data?.next &&
                    <Flex ml="auto" bg="#fff" border="1px solid #E4E7EC" boxShadow="0px 1px 2px 0px #1018280D" borderRadius="8px" padding="8px 14px" align="center" fontSize="14px" fontWeight="500" color="#344054" onClick={() => loadData("next", data?.data?.next)} cursor="pointer">
                        {
                            loading2 ?
                            <Spinner color="#7D00FF" emptyColor="lightgrey" />
                            :
                            <>
                                <Text mr="3">Next</Text>
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.16663 7.00008H12.8333M12.8333 7.00008L6.99996 1.16675M12.8333 7.00008L6.99996 12.8334" stroke="#344054" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/></svg>
                            </>
                        }
                    </Flex>
                }
            </Flex>
        }
        </>
    )
}