import React from "react";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

function Index() {
  const auth = sessionStorage.getItem("ZinelyAuth");
  const history = useHistory();

  useEffect(() => {
    if (auth !== null) {
      const rLink = sessionStorage.getItem("ZinelyRole")
      if(rLink === "tax-admin") {
        history.replace("/tax-admin");
      }
      else {
        history.replace("/provider");
      }
    } else {
      history.replace("/auth/signin");
    }
  }, []);
  return null;
}

export default Index;
