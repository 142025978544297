import { Flex, Image, Text, Spinner } from "@chakra-ui/react";
import { AuthConnect } from "../../util/request";
import { errorIcon } from "../svgs";
import InputHolder from "../inputHolder";
import { useState, useEffect, useRef } from "react";
import Toast from "../notify";





export default function Skey({ modal, setModal }) {
    const [loading, setLoading] = useState(false)
    const ref1 = useRef("")
    const ref2 = useRef("")
    const ref3 = useRef("")
    useEffect(() => {
        ref1.current.value = modal?.data[0]
        ref2.current.value = modal?.data[1]
    }, [])

    async function updateData() {
        if(ref1.current.value !== "" && ref2.current.value !== "" && ref3.current.value !== "") {
            setLoading(true)
            const res = await AuthConnect("post", "setting", {
                secretKey: ref1.current.value,
                extractionUrl: ref2.current.value,
                password: ref3.current.value,
            })
            await modal?.refresh()
            if(res?.success) {
                setModal({ isOpen: false })
                setModal({
                    isOpen: true,
                    id: "SUCCESS",
                    data: ["Settings Update", res?.message]
                })
            }
            setLoading(false)
        }
        else {
            Toast("kindly enter all fields", "error")
        }
    }

    return (
        <Flex w='100%' direction="column">
            <Flex w="100%" direction="column" mb="2">
                <InputHolder inpRef={ref1} label="Secret Key" type="password" />
                <InputHolder inpRef={ref2} label="Extraction Url" type="password" />
                <InputHolder inpRef={ref3} label="Password" type="password" />

                <Flex cursor="pointer" bg="#7D00FF" border="1px solid #7D00FF" color="#fff" borderRadius="8px" padding="10px 18px" w="100%" justify="center" align="center" fontWeight="500" onClick={() => updateData()}>{loading ? <Spinner color="#fff" emptyColor="lightgrey" /> : "Update"}</Flex>
            </Flex>
        </Flex>
    )
}