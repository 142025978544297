import img1 from "../assets/images/Logos/Binance.svg"
import img2 from "../assets/images/Logos/Flutterwave.svg"
import img3 from "../assets/images/Logos/Kucoin.svg"
import img4 from "../assets/images/Logos/luno.svg"
import img5 from "../assets/images/Logos/Paystack.svg"
import img6 from "../assets/images/Logos/Quiadx.svg"



export const sIcon = <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15 15L10.3333 10.3333M11.8889 6.44444C11.8889 9.45133 9.45133 11.8889 6.44444 11.8889C3.43756 11.8889 1 9.45133 1 6.44444C1 3.43756 3.43756 1 6.44444 1C9.45133 1 11.8889 3.43756 11.8889 6.44444Z" stroke="#475367" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


export const bellIcon = 
<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="40" height="40" rx="20" fill="#F0F2F5"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M20.8337 12.4998C20.8337 12.0396 20.4606 11.6665 20.0004 11.6665C19.5401 11.6665 19.167 12.0396 19.167 12.4998V12.9756C16.3403 13.3798 14.167 15.81 14.167 18.7489L14.167 22.0826C14.167 22.0826 14.167 22.0825 14.167 22.0826C14.167 22.0842 14.1664 22.0954 14.1628 22.1172C14.1584 22.1435 14.1507 22.1792 14.138 22.2251C14.1123 22.3181 14.0712 22.434 14.0139 22.5711C13.899 22.8458 13.7371 23.162 13.5579 23.4811C13.2212 24.0811 13.0514 24.796 13.1788 25.4759C13.3132 26.1928 13.7747 26.8181 14.5629 27.1182C15.267 27.3863 16.2042 27.6314 17.4419 27.7774C17.4714 27.8029 17.5062 27.8321 17.5462 27.8641C17.6714 27.9643 17.8504 28.0942 18.0765 28.2234C18.5253 28.4798 19.1889 28.7498 20.0004 28.7498C20.8119 28.7498 21.4754 28.4798 21.9242 28.2234C22.1504 28.0942 22.3293 27.9643 22.4545 27.8641C22.4945 27.8321 22.5294 27.8029 22.5588 27.7774C23.7965 27.6314 24.7337 27.3863 25.4378 27.1182C26.226 26.8181 26.6875 26.1928 26.8219 25.4759C26.9493 24.796 26.7796 24.0811 26.4428 23.4811C26.2637 23.162 26.1017 22.8458 25.9868 22.5711C25.9295 22.434 25.8885 22.3181 25.8627 22.2251C25.85 22.1792 25.8423 22.1435 25.838 22.1172C25.8344 22.0953 25.8338 22.0844 25.8337 22.0828C25.8337 22.0827 25.8337 22.0828 25.8337 22.0828L25.8337 22.0758V18.7493C25.8337 15.8105 23.6605 13.3798 20.8337 12.9756V12.4998ZM15.8337 18.7489C15.8337 16.4479 17.699 14.5832 20.0004 14.5832C22.3017 14.5832 24.167 16.4482 24.167 18.7493V22.0832C24.167 22.469 24.3116 22.8851 24.4492 23.2141C24.5987 23.5716 24.7946 23.9499 24.9895 24.297C25.1789 24.6345 25.2241 24.9535 25.1838 25.1688C25.1504 25.347 25.0613 25.4782 24.8448 25.5606C23.949 25.9017 22.437 26.2498 20.0004 26.2498C17.5638 26.2498 16.0517 25.9017 15.156 25.5606C14.9394 25.4782 14.8504 25.347 14.817 25.1688C14.7766 24.9535 14.8218 24.6345 15.0113 24.297C15.2062 23.9499 15.4021 23.5716 15.5516 23.2141C15.6892 22.8851 15.8337 22.469 15.8337 22.0832V18.7489Z" fill="#344054"/>
</svg>



export const seIcon = 
<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M6.33325 0.333496C3.01954 0.333496 0.333252 3.01979 0.333252 6.3335C0.333252 9.64721 3.01954 12.3335 6.33325 12.3335C7.74993 12.3335 9.05193 11.8425 10.0784 11.0214L12.5285 13.4716C12.7889 13.7319 13.211 13.7319 13.4713 13.4716C13.7317 13.2112 13.7317 12.7891 13.4713 12.5288L11.0212 10.0786C11.8423 9.05217 12.3333 7.75017 12.3333 6.3335C12.3333 3.01979 9.64696 0.333496 6.33325 0.333496ZM1.66659 6.3335C1.66659 3.75617 3.75592 1.66683 6.33325 1.66683C8.91058 1.66683 10.9999 3.75617 10.9999 6.3335C10.9999 8.91083 8.91058 11.0002 6.33325 11.0002C3.75592 11.0002 1.66659 8.91083 1.66659 6.3335Z" fill="#667185"/>
</svg>


export const fIcon = 
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2.66675 4.00016C2.66675 3.63197 2.96522 3.3335 3.33341 3.3335H12.6667C13.0349 3.3335 13.3334 3.63197 13.3334 4.00016C13.3334 4.36835 13.0349 4.66683 12.6667 4.66683H3.33341C2.96522 4.66683 2.66675 4.36835 2.66675 4.00016Z" fill="#667185"/>
<path d="M4.00008 8.00016C4.00008 7.63197 4.29856 7.3335 4.66675 7.3335H11.3334C11.7016 7.3335 12.0001 7.63197 12.0001 8.00016C12.0001 8.36835 11.7016 8.66683 11.3334 8.66683H4.66675C4.29856 8.66683 4.00008 8.36835 4.00008 8.00016Z" fill="#667185"/>
<path d="M6.00008 11.3335C5.63189 11.3335 5.33341 11.632 5.33341 12.0002C5.33341 12.3684 5.63189 12.6668 6.00008 12.6668H10.0001C10.3683 12.6668 10.6667 12.3684 10.6667 12.0002C10.6667 11.632 10.3683 11.3335 10.0001 11.3335H6.00008Z" fill="#667185"/>
</svg>



export const stIcon = 
<svg width="10" height="13" viewBox="0 0 10 13" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.47132 0.195262C5.3463 0.0702379 5.17673 0 4.99992 0C4.82311 0 4.65354 0.0702379 4.52851 0.195262L0.528514 4.19526C0.268165 4.45561 0.268165 4.87772 0.528514 5.13807C0.788864 5.39842 1.21097 5.39842 1.47132 5.13807L4.99992 1.60948L8.52851 5.13807C8.78886 5.39842 9.21097 5.39842 9.47132 5.13807C9.73167 4.87772 9.73167 4.45561 9.47132 4.19526L5.47132 0.195262Z" fill="#667185"/>
<path d="M5.47132 12.4714C5.3463 12.5964 5.17673 12.6667 4.99992 12.6667C4.82311 12.6667 4.65354 12.5964 4.52851 12.4714L0.528514 8.47141C0.268165 8.21105 0.268165 7.78895 0.528514 7.52859C0.788864 7.26825 1.21097 7.26825 1.47132 7.52859L4.99992 11.0572L8.52851 7.52859C8.78886 7.26825 9.21097 7.26825 9.47132 7.52859C9.73167 7.78895 9.73167 8.21105 9.47132 8.47141L5.47132 12.4714Z" fill="#667185"/>
</svg>


export const bkIcon = 
<svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.64 13.7698C6.74126 13.6858 6.82496 13.5827 6.88631 13.4664C6.94766 13.35 6.98546 13.2227 6.99754 13.0918C7.00961 12.9608 6.99573 12.8287 6.95669 12.7031C6.91764 12.5775 6.8542 12.4608 6.77 12.3598L3.14 7.99978L15 7.99978C15.2652 7.99978 15.5196 7.89443 15.7071 7.70689C15.8946 7.51935 16 7.265 16 6.99978C16 6.73457 15.8946 6.48021 15.7071 6.29268C15.5196 6.10514 15.2652 5.99978 15 5.99978L3.14 5.99978L6.77 1.63978C6.85405 1.53867 6.91735 1.42198 6.9563 1.2964C6.99526 1.17082 7.00909 1.03879 6.99702 0.907861C6.98495 0.77693 6.94721 0.649659 6.88595 0.533315C6.82469 0.41697 6.74112 0.313831 6.64 0.229785C6.53888 0.145739 6.4222 0.0824323 6.29662 0.04348C6.17103 0.00452776 6.03901 -0.00930777 5.90808 0.00276385C5.77715 0.0148355 5.64987 0.0525777 5.53353 0.113835C5.41719 0.175093 5.31405 0.258667 5.23 0.359785L0.23 6.35978L0.139999 6.50978L0.0699997 6.63978C-0.0193977 6.87146 -0.0193977 7.12811 0.0699997 7.35978L0.139999 7.48978L0.23 7.63978L5.23 13.6398C5.31395 13.741 5.41705 13.8247 5.5334 13.8861C5.64974 13.9474 5.77705 13.9852 5.90803 13.9973C6.03901 14.0094 6.17108 13.9955 6.29668 13.9565C6.42229 13.9174 6.53895 13.854 6.64 13.7698Z" fill="#7D00FF"/>
</svg>


export const pIcon = 
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M14 4H6C3.17157 4 1.75736 4 0.87868 4.87868C0 5.75736 0 7.17157 0 10C0 12.8284 0 14.2426 0.87868 15.1213C1.37323 15.6159 2.03743 15.8321 3.02795 15.9266C2.99998 15.2038 2.99999 13.3522 3 12.5C2.72386 12.5 2.5 12.2761 2.5 12C2.5 11.7239 2.72386 11.5 3 11.5H17C17.2761 11.5 17.5 11.7239 17.5 12C17.5 12.2761 17.2761 12.5003 17 12.5003C17 13.3525 17 15.2039 16.9721 15.9266C17.9626 15.8321 18.6268 15.6159 19.1213 15.1213C20 14.2426 20 12.8284 20 10C20 7.17157 20 5.75736 19.1213 4.87868C18.2426 4 16.8284 4 14 4ZM7 8.75C7.41421 8.75 7.75 8.41421 7.75 8C7.75 7.58579 7.41421 7.25 7 7.25H4C3.58579 7.25 3.25 7.58579 3.25 8C3.25 8.41421 3.58579 8.75 4 8.75H7ZM15 9C15.5523 9 16 8.55229 16 8C16 7.44772 15.5523 7 15 7C14.4477 7 14 7.44772 14 8C14 8.55229 14.4477 9 15 9Z" fill="#637381"/>
<path d="M15.1211 0.878679C14.2424 0 12.8282 0 9.99977 0C7.17134 0 5.75712 0 4.87844 0.87868C4.38608 1.37105 4.16961 2.03157 4.07444 3.01484C4.63368 2.99996 5.25183 2.99998 5.92943 3H14.0706C14.748 2.99998 15.366 2.99996 15.9251 3.01483C15.8299 2.03156 15.6135 1.37105 15.1211 0.878679Z" fill="#637381"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M16 12.5C16 15.3284 16 18.2426 15.1213 19.1213C14.2426 20 12.8284 20 10 20C7.17158 20 5.75736 20 4.87868 19.1213C4 18.2426 4 15.3284 4 12.5H16ZM13.75 14.75C13.75 15.1642 13.4142 15.5 13 15.5H7C6.58579 15.5 6.25 15.1642 6.25 14.75C6.25 14.3358 6.58579 14 7 14H13C13.4142 14 13.75 14.3358 13.75 14.75ZM11.75 17.75C11.75 18.1642 11.4142 18.5 11 18.5H7C6.58579 18.5 6.25 18.1642 6.25 17.75C6.25 17.3358 6.58579 17 7 17H11C11.4142 17 11.75 17.3358 11.75 17.75Z" fill="#637381"/>
</svg>


export const crpto_arr = {
    "ETH": "https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png",
    "SOL": "https://s2.coinmarketcap.com/static/img/coins/64x64/5426.png",
    "BTC": "https://s2.coinmarketcap.com/static/img/coins/64x64/1.png",
    "USDT": "https://s2.coinmarketcap.com/static/img/coins/64x64/825.png",
    "BNB": "https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png",
    "DOGE": "https://s2.coinmarketcap.com/static/img/coins/64x64/74.png"
}


export const providers_arr = {
    "binance": img1,
    "flutterwave": img2,
    "kucoin": img3,
    "luno": img4,
    "paystack": img5,
    "quidax": img6,
}



export const binLogo = 
<svg width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="58" height="58" rx="3.2" fill="#0B0E11"/>
<path d="M28.6001 20.5995L22.9294 26.27L19.6297 22.9704L28.6001 14L37.5732 22.9733L34.2736 26.273L28.6001 20.5995Z" fill="#F0B90B"/>
<path d="M20.5993 28.5997L17.2997 25.3001L14 28.5999L17.2996 31.8995L20.5993 28.5997Z" fill="#F0B90B"/>
<path d="M28.6001 36.6004L22.9294 30.93L19.625 34.2252L19.6296 34.2297L28.6001 43.2L37.5732 34.2267L37.575 34.2251L34.2735 30.9271L28.6001 36.6004Z" fill="#F0B90B"/>
<path d="M39.9006 31.8999L43.2002 28.6003L39.9006 25.3007L36.6011 28.6003L39.9006 31.8999Z" fill="#F0B90B"/>
<path d="M31.9482 28.5984H31.9467L31.9498 28.6L28.5999 31.95L25.2546 28.6046L25.25 28.6L28.5999 25.25L31.9482 28.5984Z" fill="#F0B90B"/>
</svg>



export const checkIcon = 
<svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M53.6668 27.0002C53.6668 41.7278 41.7278 53.6668 27.0002 53.6668C12.2726 53.6668 0.333496 41.7278 0.333496 27.0002C0.333496 12.2726 12.2726 0.333496 27.0002 0.333496C41.7278 0.333496 53.6668 12.2726 53.6668 27.0002ZM37.7477 18.9193C38.5288 19.7003 38.5288 20.9667 37.7477 21.7477L24.4144 35.081C23.6333 35.8621 22.367 35.8621 21.5859 35.081L16.2526 29.7477C15.4716 28.9667 15.4716 27.7003 16.2526 26.9193C17.0337 26.1382 18.3 26.1382 19.081 26.9193L23.0002 30.8384L28.9597 24.8788L34.9193 18.9193C35.7003 18.1382 36.9667 18.1382 37.7477 18.9193Z" fill="#219653"/>
</svg>



export const errorIcon = 
<svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M53.6668 27.0002C53.6668 12.2726 41.7278 0.333496 27.0002 0.333496C12.2726 0.333496 0.333496 12.2726 0.333496 27.0002C0.333496 41.7278 12.2726 53.6668 27.0002 53.6668C41.7278 53.6668 53.6668 41.7278 53.6668 27.0002ZM27.0002 11.6668C28.1047 11.6668 29.0002 12.5623 29.0002 13.6668V29.6668C29.0002 30.7714 28.1047 31.6668 27.0002 31.6668C25.8956 31.6668 25.0002 30.7714 25.0002 29.6668V13.6668C25.0002 12.5623 25.8956 11.6668 27.0002 11.6668ZM27.0002 40.3335C28.4729 40.3335 29.6668 39.1396 29.6668 37.6668C29.6668 36.1941 28.4729 35.0002 27.0002 35.0002C25.5274 35.0002 24.3335 36.1941 24.3335 37.6668C24.3335 39.1396 25.5274 40.3335 27.0002 40.3335Z" fill="#F9362F"/>
</svg>



export const pendingIcon = 
<svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M53.6668 27.0002C53.6668 12.2726 41.7278 0.333496 27.0002 0.333496C12.2726 0.333496 0.333496 12.2726 0.333496 27.0002C0.333496 41.7278 12.2726 53.6668 27.0002 53.6668C41.7278 53.6668 53.6668 41.7278 53.6668 27.0002ZM27.0002 11.6668C28.1047 11.6668 29.0002 12.5623 29.0002 13.6668V29.6668C29.0002 30.7714 28.1047 31.6668 27.0002 31.6668C25.8956 31.6668 25.0002 30.7714 25.0002 29.6668V13.6668C25.0002 12.5623 25.8956 11.6668 27.0002 11.6668ZM27.0002 40.3335C28.4729 40.3335 29.6668 39.1396 29.6668 37.6668C29.6668 36.1941 28.4729 35.0002 27.0002 35.0002C25.5274 35.0002 24.3335 36.1941 24.3335 37.6668C24.3335 39.1396 25.5274 40.3335 27.0002 40.3335Z" fill="#865503"/>
</svg>






