import Logo from "../assets/images/logoBig.svg"
import { Flex, Image, Text, Spinner } from "@chakra-ui/react";
import { AuthConnect } from "../util/request";
import Header from "../components/header";
import { useHistory, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import Toast from "../components/notify";
import Status from "../components/status";



export default function Settings({ userData, setModal }) {
    const [data, setData] = useState()
    const [loading, setLoading] = useState(true)
    const history = useHistory()

    async function loadData() {
        setLoading(true)
        const res = await AuthConnect("get", "setting")
        setData(res?.data)
        setLoading(false)
    }

    useEffect(() => {
        loadData()
    }, [])


    return (
        <Flex w="100%" direction="column" pt="6">
            <Header name="Settings" sub="Manage your settings here" />

            <Flex mt="8" border="1px solid #EDEDF2" w="629px" borderRadius="12px" bg="#fff" padding="24px" direction="column">
                <Text color="#1A1A21" fontWeight="600" fontSize="20px">Verification</Text>
                <Text mt="1" color="#8C94A6" fontSize='16px'>These are your personal details, they are visible to the public</Text>
                <Flex w="100%" mt="28px" mb="4px" h="1px" bg="#EDEDF2"></Flex>

                <Flex w="100%" direction="column">
                    <Flex w="100%" justify="space-between" padding="24px 16px" borderBottom="1px solid #F7F9FC">
                        <Text color="#1A1A21" fontWeight="600" textTransform="capitalize" fontSize="14px">Status:</Text>
                        <Text fontSize="14px" fontWeight="500" color="#101928"><Status status={userData?.status} /></Text>
                    </Flex>

                    <Flex w="100%" justify="space-between" padding="24px 16px" borderBottom="1px solid #F7F9FC">
                        <Text color="#1A1A21" fontWeight="600" textTransform="capitalize" fontSize="14px">Date Created:</Text>
                        <Text fontSize="14px" fontWeight="500" color="#101928">{userData?.createdAt?.substr(0,10)}</Text>
                    </Flex>
                </Flex>
            </Flex>




            <Flex mt="5" border="1px solid #EDEDF2" w="629px" borderRadius="12px" bg="#fff" padding="24px" direction="column">
                <Text color="#1A1A21" fontWeight="600" fontSize="20px">Secret Keys</Text>
                <Text mt="1" color="#8C94A6" fontSize='16px'>These are your personal details, they are not visible to the public</Text>
                <Flex w="100%" mt="28px" mb="4px" h="1px" bg="#EDEDF2"></Flex>

                <Flex w="100%" direction="column">
                    <Flex w="100%" justify="space-between" padding="24px 16px" borderBottom="1px solid #F7F9FC">
                        <Text color="#1A1A21" fontWeight="600" textTransform="capitalize" fontSize="14px">Live Api Key:</Text>
                        <Text fontSize="14px" fontWeight="500" color="#101928">{data?.APIKey}</Text>
                    </Flex>

                    <Flex w="100%" justify="space-between" padding="24px 16px" borderBottom="1px solid #F7F9FC">
                        <Text color="#1A1A21" fontWeight="600" textTransform="capitalize" fontSize="14px">Extraction Url:</Text>
                        <Flex align="center">
                            <Flex cursor="pointer" color="#667185" fontSize="14px" justify='center' align="center" padding="6px 16px" borderRadius="8px" border="1px solid #667185" mr="4" onClick={() => {
                                Toast("Key Copied to your clipboard", "success")
                                 navigator.clipboard.writeText( data?.extractionUrl)
                            }}>Copy</Flex>
                            <Flex cursor="pointer" color="#667185" fontSize="14px" justify='center' align="center" padding="6px 16px" borderRadius="8px" border="1px solid #667185" onClick={() => {
                                setModal({
                                    isOpen: true,
                                    id: "SKEY",
                                    data: [data?.secretKey, data?.extractionUrl],
                                    refresh: loadData,
                                })
                            }}>Update</Flex>
                        </Flex>
                    </Flex>


                    <Flex w="100%" justify="space-between" padding="24px 16px" borderBottom="1px solid #F7F9FC">
                        <Text color="#1A1A21" fontWeight="600" textTransform="capitalize" fontSize="14px">Secret Key:</Text>
                        <Flex align="center">
                            <Flex cursor="pointer" color="#667185" fontSize="14px" justify='center' align="center" padding="6px 16px" borderRadius="8px" border="1px solid #667185" mr="4" onClick={() => {
                                Toast("Key Copied to your clipboard", "success")
                                 navigator.clipboard.writeText( data?.secretKey)
                            }}>Copy</Flex>
                            <Flex cursor="pointer" color="#667185" fontSize="14px" justify='center' align="center" padding="6px 16px" borderRadius="8px" border="1px solid #667185" onClick={() => {
                                setModal({
                                    isOpen: true,
                                    id: "SKEY",
                                    data: [data?.secretKey, data?.extractionUrl],
                                    refresh: loadData,
                                })
                            }}>Update</Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>


        </Flex>
    )
}