import demoAvatar from "../assets/images/demo.svg"
import { Flex, Image, Text, Spinner } from "@chakra-ui/react";
import { sIcon, bellIcon } from "./svgs";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { AuthConnect } from "../util/request";


export default function NavBar({ setModal }) {
    const [menu, setMenu] = useState(false)

    return (
        <Flex w="100%" bg="#fff" padding="12px 36px" justify="space-between" align="center" position="sticky" top="0" zIndex="10" borderBottom="1px solid #E4E7EC">
            <Flex w="629px" position="relative" align="center">
                <input style={{ width: "100%", padding: "10px 12px 10px 42px", border: "1px solid transparent", borderRadius: "6px", background: "#F9FAFB" }} type="search" placeholder="Search here..." />

                <Text position="absolute" py="2px" px="2px" cursor="pointer" left="12px" color="#667185">{sIcon}</Text>
            </Flex>
            <Flex align="center">
                <Flex cursor="pointer" mr="4">{bellIcon}</Flex>
                
                <Flex position="relative">
                    <Flex w="40px" h="40px" cursor="pointer" borderRadius="100%" justify="flex-end" backgroundImage={demoAvatar} backgroundRepeat="no-repeat" backgroundSize="cover" backgroundPosition="center center" onClick={() => menu ? setMenu(false) : setMenu(true)}>
                    {
                        menu && <Flex data-aos="fade-up" data-aos-duration="400" mt="10" right="-10px" bgColor="#fff" position="absolute" w="100px" borderRadius="8px" boxShadow="-20px 20px 40px -4px #919EAB3D, 0px 0px 2px 0px #919EAB3D" direction="column">
                            <Text cursor="pointer" w="100%" textAlign="center" px="5" py="4" onClick={() => setModal({
                                isOpen: true,
                                id: "LOGOUT",
                            })}>Sign Out</Text>
                        </Flex>
                    }
                </Flex>
                </Flex>
            </Flex>
        </Flex>
    )
}