import Logo from "../assets/images/logoBig.svg"
import { Flex, Image, Text, Spinner } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import InputHolder from "../components/inputHolder";
import { useState, useRef } from "react";
import { AuthConnect, SignIn } from "../util/request";
import Toast from "../components/notify";
import bgImg from "../assets/images/bg.png"

export default function Auth({ setUserData }) {
    const [pass, setPass] = useState(false)
    const [loading, setLoading] = useState(false)
    const history = useHistory()
    const ref1 = useRef("")
    const ref2 = useRef("")

    async function allowLogin() {
        var a = ref1.current.value
        var b = ref2.current.value
        if(a !== "" && b !== "") {
            setPass(true)
        }
        else {
            setPass(false)
        }
    }


    async function authUser(e) {
        e.preventDefault()
        if(pass) {
            setLoading(true)
            const res = await SignIn("post", "auth/login", {
                email: ref1.current.value,
                password: ref2.current.value,
            })
            if(res?.success) {
                sessionStorage.setItem("ZinelyToken", res?.data?.accesstoken)
                sessionStorage.setItem("ZinelyRole", res?.data?.role)
                sessionStorage.setItem("ZinelyUid", res?.data?.uid)
                sessionStorage.setItem("ZinelyAuth", true)
                const newRes = await AuthConnect("get", "user")
                sessionStorage.setItem("userData", JSON.stringify(newRes?.data))
                setUserData(newRes?.data)
                if(res?.data?.role === "tax-admin") {
                    sessionStorage.setItem("ZinelyType", "tax")
                    history.replace("/tax-admin")
                }
                else {
                    sessionStorage.setItem("ZinelyType", "provider")
                    history.replace("/provider")
                }
                Toast("Welcome Back "+newRes?.data?.firstName, "success")
            }
            setLoading(false)
        }
    }

    return (
        <Flex w="100%" h="100vh" justify="space-between">
            <Flex flex="1" justify="center" align="center" backgroundImage={bgImg} backgroundRepeat="no-repeat" backgroundSize="cover" backgroundPosition="center" px="5" py="5">
                <Image src={Logo} w="216px" />
            </Flex>
            <Flex flex="1" direction="column" bg="#fff" align="center" justify="center" px="5" py="12">
                <Flex w="442px" direction="column">
                    <Text fontWeight="700" color='#1B1818' fontSize="36px">Sign In</Text>
                    <Text color="#645D5D" fontSize="14px">Enter your details to access your account</Text>

                    <form onSubmit={(e) => authUser(e)} style={{ width: "100%" }}>
                        <Flex direction="column" mt="12">
                            <InputHolder type="email" label="Email Address" inpRef={ref1} onInput={() => allowLogin()} required />
                            <InputHolder type="password" label="Password" inpRef={ref2} onInput={() => allowLogin()} required />
                        </Flex>
                        <Flex mt="3" w="100%">
                            <Flex as="button" transition="200ms ease-in-out" w="100%" padding="16px 24px" bg={pass ? "#7D00FF" : "#D0D5DD"} color="#fff" justify="center" align="center" fontWeight="500" borderRadius="8px" cursor={pass ? "pointer" : "not-allowed"}>{loading ? <Spinner color="#fff" emptyColor="lightgrey" /> : "Sign In"}</Flex>
                        </Flex>
                        <Text mt="8" fontSize="14px" color="#667185">Don’t have an account? <Text as="span" color="#7D00FF" ml="1" cursor="pointer" onClick={() => history.push("/auth/signup")} fontWeight="500">Sign Up</Text></Text>
                    </form>
                </Flex>
            </Flex>
        </Flex>
    )
}