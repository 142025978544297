import Logo from "../assets/images/logoBig.svg"
import { Flex, Image, Text, Spinner } from "@chakra-ui/react";
import { useHistory, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { AuthConnect } from "../util/request";
import Header from "../components/header";
import Table from "../components/table";
import TabsMenu from "../components/tabs";
import Back from "../components/back";


export default function Payments({ setModal }) {
    const [screen, setScreen] = useState(0)
    const zType = sessionStorage.getItem("ZinelyType")
    const history = useHistory()
    const [tab, setTab] = useState(0)
    const [data, setData] = useState()
    const [data2, setData2] = useState()
    const [data3, setData3] = useState()
    const [data4, setData4] = useState()
    const [filter, setFilter] = useState("Filter")
    const [sort, setSort] = useState("Sort")
    const [search, setSearch] = useState("Search")
    const [loading, setLoading] = useState(false)
    const [loading2, setLoading2] = useState(false)
    const [loading3, setLoading3] = useState(false)
    const [loading4, setLoading4] = useState(false)
    const [loading5, setLoading5] = useState(false)
    const [loading6, setLoading6] = useState(false)
    const [loading7, setLoading7] = useState(false)
    const [loading8, setLoading8] = useState(false)
    const [loading9, setLoading9] = useState(false)
    const [loading10, setLoading10] = useState(false)
    const [loading11, setLoading11] = useState(false)
    const [loading12, setLoading12] = useState(false)


    async function loadData(direction, id) {
        direction === "prev" ? setLoading3(true) : direction === "next" ? setLoading2(true) : setLoading(true)
        const res = await AuthConnect("get", direction === "prev" ? "payment/"+(zType === "tax" ? "admin" : "provider")+"?previous="+id : direction === "next" ? "payment/"+(zType === "tax" ? "admin" : "provider")+"?next="+id : "payment/"+(zType === "tax" ? "admin" : "provider"))
        console.log(res)
        setData(res)
        direction === "prev" ? setLoading3(false) : direction === "next" ? setLoading2(false) : setLoading(false)
    }

    async function loadData2(direction, id) {
        direction === "prev" ? setLoading6(true) : direction === "next" ? setLoading5(true) : setLoading4(true)
        const res = await AuthConnect("get", direction === "prev" ? "payment/"+(zType === "tax" ? "admin" : "provider")+"?status=success&previous="+id : direction === "next" ? "payment/"+(zType === "tax" ? "admin" : "provider")+"?status=success&next="+id : "payment/"+(zType === "tax" ? "admin" : "provider")+"?status=success")
        setData2(res)
        direction === "prev" ? setLoading6(false) : direction === "next" ? setLoading5(false) : setLoading4(false)
    }

    async function loadData3(direction, id) {
        direction === "prev" ? setLoading9(true) : direction === "next" ? setLoading8(true) : setLoading7(true)
        const res = await AuthConnect("get", direction === "prev" ? "payment/"+(zType === "tax" ? "admin" : "provider")+"?status=pending&previous="+id : direction === "next" ? "payment/"+(zType === "tax" ? "admin" : "provider")+"?status=pending&next="+id : "payment/"+(zType === "tax" ? "admin" : "provider")+"?status=pending")
        setData3(res)
        direction === "prev" ? setLoading9(false) : direction === "next" ? setLoading8(false) : setLoading7(false)
    }

    async function loadData4(direction, id) {
        direction === "prev" ? setLoading12(true) : direction === "next" ? setLoading11(true) : setLoading10(true)
        const res = await AuthConnect("get", direction === "prev" ? "payment/"+(zType === "tax" ? "admin" : "provider")+"?status=failed&previous="+id : direction === "next" ? "payment/"+(zType === "tax" ? "admin" : "provider")+"?status=failed&next="+id : "payment/"+(zType === "tax" ? "admin" : "provider")+"?status=failed")
        setData4(res)
        direction === "prev" ? setLoading12(false) : direction === "next" ? setLoading11(false) : setLoading10(false)
    }

    useEffect(() => {
        loadData()
        loadData2()
        loadData3()
        loadData4()
    }, [])

    const tabs = ["All Payment", "Success", "Pending", "Failed"]
    const tableHead = ["Created At", "Provider", "Amount", "Reference", "Payment Method", "Date Verified", "Status"]

    async function updateScreen(item) {
        history.push("/"+(zType === "tax" ? "tax-admin" : "provider")+"/reports/"+item?.metadata?.filingId)
    }



    return (
        <Flex w="100%" direction="column" pt="6">
            
            <Flex w="100%" direction="column" display={screen === 0 ? "flex" : "none"}>
                <Header name="Payments" sub="Manage payments here" type="payment" />

                <Flex w="100%" direction="column" mt="8">
                    <TabsMenu tabs={tabs} tab={tab} setTab={setTab} setModal={setModal} filter={filter} setFilter={setFilter} sort={sort} setSort={setSort} search={search} setSearch={setSearch} />
                </Flex>

                <Flex direction="column" mt="5" w="100%">
                    {
                        tab === 0 ?
                        <Table type="payment" data={data} loading={loading} loading2={loading2} loading3={loading3} tableHead={tableHead} loadData={loadData} updateScreen={updateScreen} />
                        :
                        tab === 1 ?
                        <Table type="payment" data={data2} loading={loading4} loading2={loading5} loading3={loading6} tableHead={tableHead} loadData={loadData2} updateScreen={updateScreen} />
                        :
                        tab === 2 ?
                        <Table type="payment" data={data3} loading={loading7} loading2={loading8} loading3={loading9} tableHead={tableHead} loadData={loadData3} updateScreen={updateScreen} />
                        :
                        tab === 3 ?
                        <Table type="payment" data={data4} loading={loading10} loading2={loading11} loading3={loading12} tableHead={tableHead} loadData={loadData4} updateScreen={updateScreen} />
                        :
                        <></>
                    }
                </Flex>
            </Flex>















            <Flex w="100%" direction="column" display={screen === 1 ? "flex" : "none"}>
            </Flex>




        </Flex>
    )
}