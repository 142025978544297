import { Flex, Image, Text, Spinner } from "@chakra-ui/react";
import { AuthConnect } from "../../util/request";
import { errorIcon } from "../svgs";
import InputHolder from "../inputHolder";
import { useState, useEffect, useRef } from "react";
import Toast from "../notify";
import { useHistory } from "react-router-dom";





export default function Logout({ modal, setModal }) {
    const [loading, setLoading] = useState(false)
    const history = useHistory()

    async function signOut() {
        setLoading(true)
        await AuthConnect("post", "auth/logout")
        sessionStorage.clear()
        setModal({ isOpen: false })
        history.push("/")
        setLoading(false)
    }
    return (
        <Flex w='100%' direction="column">
            <Flex w="100%" direction="column" mb="2">
                <Text my="12" textAlign="center">Are you sure you want to sign out?</Text>

                <Flex cursor="pointer" bg="#7D00FF" border="1px solid #7D00FF" color="#fff" borderRadius="8px" padding="10px 18px" w="100%" justify="center" align="center" fontWeight="500" onClick={() => signOut()}>{loading ? <Spinner color="#fff" emptyColor="lightgrey" /> : "Yes"}</Flex>
            </Flex>
        </Flex>
    )
}