import { Flex, Image, Text, Spinner } from "@chakra-ui/react";
import Success from "./success";
import Skey from "./skey";
import Logout from "./logout";




export default function Modal({ modal, setModal }) {
    return (
        <Flex w="100%" direction="column" position="fixed" top="0" left="0" h="100%" justify="center" align="center" zIndex="1000">  
            <Flex zIndex="0" w="100%" bg="rgba(22, 28, 36, .8)" position="absolute" top="0" left="0" h="100%" cursor="pointer" onClick={() => setModal({
                isOpen: false,
            })}></Flex>

            <Flex data-aos="fade-up" data-aos-duration="500" zIndex="1" w="454px" bg="#fff" maxH="90vh" overflowY="scroll" className="customScroll" padding="40px 24px 24px 24px" boxShadow="-40px 40px 80px -8px #0000003D" borderRadius="16px" direction="column">
                {
                    modal?.id === "SUCCESS" ?
                    <Success modal={modal} setModal={setModal} />
                    :
                    modal?.id === "SKEY" ?
                    <Skey modal={modal} setModal={setModal} />
                    :
                    modal?.id === "LOGOUT" ?
                    <Logout modal={modal} setModal={setModal} />
                    :
                    <></>
                }
            </Flex>
        </Flex>
    )
}