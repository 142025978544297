import Logo from "../assets/images/logoBig.svg"
import { Flex, Image, Text, Spinner } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import { AuthConnect } from "../util/request";
import { seIcon, stIcon, fIcon } from "../components/svgs";


export default function TabsMenu({ tabs, tab, setTab, setModal, filter, setFilter, sort, setSort, search, setSearch }) {
    return (
        <Flex w="100%" justify="space-between" align="center">
            <Flex>
                {
                    tabs.map((item, index) => (
                        <Flex onClick={() => setTab(index)} padding="16px" key={index} fontSize="14px" color={index === tab ? "#7D00FF" : "#667185"} fontWeight="500" borderBottom={index === tab ? "2px solid #7D00FF" : "2px solid #E4E7EC"} cursor="pointer" transition="200ms ease-in-out" _hover={{ color: "#7D00FF", borderBottom: "2px solid #7D00FF"}}>{item}</Flex>
                    ))
                }
            </Flex>
            <Flex align="center">
                <Flex fontSize="14px" color="#667185" align="center" fontWeight="500" cursor="pointer">
                    <Text mr='2'>{seIcon}</Text>
                    Search
                </Flex>
                <Flex ml="6" fontSize="14px" color="#667185" align="center" fontWeight="500" cursor="pointer">
                    <Text mr='2'>{fIcon}</Text>
                    {filter}
                </Flex>
                <Flex ml="6" fontSize="14px" color="#667185" align="center" fontWeight="500" cursor="pointer">
                    <Text mr='2'>{stIcon}</Text>
                    {sort}
                </Flex>
            </Flex>
        </Flex>
    )
}