import axios from "axios";
import Toast from "../components/notify";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";


export async function AuthConnect(type, url, data) {
  //AXIOS CLIENT HEADER
  const authToken = sessionStorage.getItem("ZinelyToken");
  const client = axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL,
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      accesstoken: authToken,
    },
  });
  try {
    const response =
      type === "post"
        ? await client.post(url, data)
        :  type === "put"
        ? await client.put(url, data)
        : type === "delete"
        ? await client.delete(url)
        : await client.get(url);
    return response.data;
  } catch (error) {
    if (error.response?.status === 401) {
      Toast("Hello your session has timed out, Kindly login again", "error");
      setTimeout(() => {
        sessionStorage.clear();
        window.location.replace("/");
      }, 3000);
    } else if (error?.response?.status) {
      if(error?.response?.status !== 404) {
        Toast(error.response?.data?.message, "error");
      }
    } else {
      Toast("Cannot connect at the moment, please try again", "error");
    }
  }
}



export async function SignIn(type, url, data) {
    const client = axios.create({
      baseURL: process.env.REACT_APP_SERVER_URL,
      headers: {
        "Content-Type": "application/json",
      },
    });
    try {
      const response =
      type === "post" ? await client.post(url, data) : await client.get(url);
      return response.data;
    } catch (error) {
      if (error.response?.status) {
        Toast(error.response?.data?.message, "error");
      } else {
        Toast("Cannot connect at the moment, please try again", "error");
      }
    }
}


export const requireAuth = (Component) => {
    return (props) => {
      const auth = sessionStorage.getItem("ZinelyAuth");
      const history = useHistory();
  
      useEffect(() => {
        if (auth === null) {
          history.replace("/");
        }
      }, []);
  
      return <Component {...props} />;
    };
};
