import { Flex } from "@chakra-ui/react";

export default function Status({ status }) {
    const isOrange = ["pending", "abadoned"].includes(status)
    const isBlue = ["part payment"].includes(status)
    const isRed = ["failed", "overdue", "disconnected"].includes(status)

    return (
        <Flex bg={isOrange ? "#FEF6E7" : isRed ? "#FBEAE9" : isBlue ? "#E3EFFC" : "#E7F6EC"} color={isOrange ? "#865503" : isRed ? "#9E0A05" : isBlue ? "#04326B" : "#036B26"} padding="2px 12px" borderRadius="12px" fontSize="12px" fontWeight="500" align="center" textTransform="capitalize">
            <Flex flex="1">{status}</Flex>
        </Flex>
    )
}